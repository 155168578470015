import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import ErrorAlertIcon from '../common/Icons/ErrorAlertIcon';
import InfoAlertIcon from '../common/Icons/InfoAlertIcon';
import SuccessAlertIcon from '../common/Icons/SuccessAlertIcon';
import WarningAlertIcon from '../common/Icons/WarningAlertIcon';

const variantIcon = {
  success: SuccessAlertIcon,
  warning: WarningAlertIcon,
  error: ErrorAlertIcon,
  info: InfoAlertIcon,
};

const useStyles1 = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1, 2)
  },
  success: {
    backgroundColor: '#DAF4DB',
    border: '1px solid #2EBC31',
    color: '#318133',
    boxShadow: 'none'
  },
  error: {
    backgroundColor: '#F7E3E4',
    border: '1px solid #FF4B55',
    color: '#D52C36',
    boxShadow: 'none'
  },
  info: {
    backgroundColor: '#EEEFF4',
    border: '1px solid #CACCD5',
    color: '#6F7289',
    boxShadow: 'none'
  },
  warning: {
    backgroundColor: '#FBF3DC',
    border: '1px solid #FAC948',
    color: '#AB8D3C',
    boxShadow: 'none'
  },
  box: {
    width: '100%'
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
  },
  message: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
  },
  iconButton: {
    padding: 0
  }
}));

export function MySnackbarContentWrapper(props) {
  const classes = useStyles1();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      style={{ padding: '7px 20px' }}
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

MySnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
};

const useStyles2 = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

export default function CustomizedSnackbars() {
  const classes = useStyles2();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" className={classes.margin} onClick={handleClick}>
        Open success snackbar
      </Button>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <MySnackbarContentWrapper
          onClose={handleClose}
          variant="success"
          message="This is a success message!"
        />
      </Snackbar>
      <MySnackbarContentWrapper
        variant="error"
        className={classes.margin}
        message="This is an error message!"
      />
      <MySnackbarContentWrapper
        variant="warning"
        shadow={{ alertBoxShadow: { color: 'red !important' } }}
        className={classes.margin}
        message="This is a warning message!"
      />
      <MySnackbarContentWrapper
        variant="info"
        className={classes.margin}
        message="This is an information message!"
      />
      <MySnackbarContentWrapper
        variant="success"
        className={classes.margin}
        message="This is a success message!"
      />
    </div>
  );
}

export function CustomSnackbarContent(props) {
  const classes = useStyles1();
  const { variant, message, className, onClose, closeable } = props
  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      classes={{ message: classes.box, root: classes.root }}
      aria-describedby="client-snackbar"
      message={
        <Box display='flex'>
          <Box flexGrow={1}>
            <span id="client-snackbar" className={classes.message}>
              {message && message.replace('Error:','')}
            </span>
          </Box>
          {closeable !== false && <Box>
            <IconButton key="close" aria-label="close" color="inherit" onClick={onClose} className={classes.iconButton}>
              <CloseIcon className={classes.icon} />
            </IconButton>
          </Box>}
        </Box>
      }
    />
  )
}

CustomSnackbarContent.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
};