import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import CustomTextField from './TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import SearchIcon from '@material-ui/icons/Search';
import { InputAdornment, IconButton, MenuItem } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles(theme => ({
    label: {
        color: '#626680',
        fontSize: '12px',
        lineHeight: '18px',
        paddingBottom: 5
    },
    input: {
        padding: '2px 20px 0',
        height: '56px',
        display: 'flex',
        alignItems: 'center'
    },
    inputNotched: {
        outline: 'none',
        border: 0,
        borderRadius: 0
    },
    root: {
        height: '56px'
    },
    menuPaper: {
        [theme.breakpoints.up('sm')]: {
            maxWidth: '500px',
        },
        maxHeight: '250px',
        marginTop: theme.spacing(1)
    },
    notchedOutline: {
        border: '1px solid #CACCD5',
        borderRadius: 8,
    },
    search: {
        padding: 0,
        marginBottom: theme.spacing(1),
        borderBottom: '1px solid #CACCD5',
        '&:hover': {
            backgroundColor: '#fff !important'
        }
    },
    placeholder: {
      color: '#9D9C9C'
    },
    IconProps: {
        right: '14px',
        color: '#1160F7'
    }
}))

const Select = (props) => {
    const classes = useStyles();
    let { variant, label, error, field, value, form, children, placeholder, hideSearch, onChange, disabled, readOnly = false, onSearch, hasCustomNotAvailOption, searchPlaceholder, iconProps, setError } = props;

    const [filter, setFilter] = useState('');
    const [options, setOptions] = useState(null);
    const [open, setOpen] = useState(false);

    useEffect(() => { setOptions(children)}, [children])

    if (form) {
        const { touched, errors, submitCount } = form;
        let field_name = field.name;
        if (field_name.includes('ben_phone1_country')) field_name = field_name.replace("_country", "")
        if (submitCount > 0) error = errors[field_name] || '';
        else error = touched[field_name] && errors[field_name] ? errors[field_name] : ''
        setError && setError(error);
    }

    if (field) {
        if (field.value == undefined) field.value = '';
    }

    useEffect(() => {
        if (!open) {
            setFilter('');
            setOptions(children);
            if(onSearch) onSearch('')
        } else {
            if (onChange) onChange({ target: { value: '' }});
        }
    }, [open]);

    const extraProps = {
        ...props,
        label: null
    }

    const handleClick = (e) => {
        e.stopPropagation();
    }

    const handleChange = (e) => {
        e.preventDefault();
        if (children && children.length && children[0]?.props && children[0]?.props?.value && !onSearch) {
            setFilter(e.target.value);  
            const filterResults = children?.filter(value => value.props && value.props.label && value.props.label?.toUpperCase()?.includes(e.target.value?.toUpperCase()));
            setOptions(filterResults);
        }
            if (onSearch) {
                setFilter(e.target.value);  
                onSearch(e.target.value);
            }
    }

    const placeholderProps = {};

    if (placeholder && !value) {
        placeholderProps.displayEmpty = true;
        placeholderProps.renderValue = () => <div className={classes.placeholder}>{placeholder}</div>;
    }

    return (
        <FormControl fullWidth error={error ? true : false} classes={{ marginNormal: classes.marginNormal }}>
            {label && 
                <Typography className={classes.label}>
                    {label}
                </Typography>
            }

            <TextField
                InputProps={{
                    classes: {
                        root: classes.root,
                        input: classes.input,
                        notchedOutline: classes.notchedOutline
                    },
                    readOnly,
                }}
                select
                {...field}
                {...extraProps}
                margin='none'
                onClick={() => { if (!disabled) {
                    setOpen(!open);
                }}}
                SelectProps={{
                    IconComponent: open ? KeyboardArrowUp : KeyboardArrowDown,
                    classes: {
                        icon: classes.IconProps,
                    },
                    ...iconProps,
                    ...placeholderProps,
                    MenuProps: {
                        autoFocus: false,
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center'
                        },
                        transformOrigin: {
                            vertical: 'top',
                            horizontal: 'center'
                        },
                        getContentAnchorEl: null,
                        PaperProps: {
                            elevation: 0
                        },
                        classes: {
                            paper: classes.menuPaper,
                            outlined: classes.outlined
                        },
                        onClose: () => {
                            setOpen(false)
                        },
                        open: open,
                        onKeyDown: (e) => handleClick(e),
                        disableAutoFocusItem: true
                    }
                }}
                variant={variant ? variant : 'outlined'}
                error={error ? true : false}>
                {((!hideSearch && children && children.length > 7) || onSearch) && (
                    <MenuItem classes={{ root: classes.search }} onKeyDown={handleClick}>
                        <CustomTextField
                            autoFocus
                            value={filter}
                            onChange={handleChange}
                            onClick={handleClick}
                            fullWidth
                            classes={{ notchedOutline: classes.inputNotched }}
                            placeholder={searchPlaceholder || 'Search'}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment>
                                        <IconButton size='small'>
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </MenuItem>
                )}
                {!hideSearch && options && !options.length && !hasCustomNotAvailOption && (
                    <MenuItem disabled>{'No options found.'}</MenuItem>
                )}
                {options}
            </TextField>
            {error && !setError && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    )
}

export default Select;