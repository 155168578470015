import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Typography, Paper, Box } from '@material-ui/core';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import React from 'react';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles(theme => ({
  listbox: {
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
  label: {
    color: '#626680',
    fontSize: '12px',
    lineHeight: '18px',
    paddingBottom: 5
  },
  inputRoot: {
    borderRadius: '8px',
    height: '48px'
  },
  paper: {
    border: '1px solid #CACCD5',
    borderRadius: 5,
    marginTop: '12px'
  },
  option: {
    padding: theme.spacing(1, 2),
    fontSize: '16px',
    lineHeight: '24px',
    color: '#31517A',
    "&:hover": {
      backgroundColor: '#F1F6FF !important'
    }
  },
  box: {
    width: '100%'
  }
}));

export default function Virtualize(props) {
  const classes = useStyles();

  let {
    options = [],
    label,
    error,
    field,
    form
  } = props;

  if (form) {
    const { touched, errors } = form
    error = touched[field.name] && errors[field.name] ? errors[field.name] : ''
  }

  const handleChange = (e, value) => {
    if (form) form.setFieldValue(field.name, value || '', false);
  }

  const CustomPaper = (props) => <Paper elevation={0} {...props} classes={{ root: classes.paper }} />

  return (
    <FormControl fullWidth error={Boolean(error)} >
      {label && <Typography className={classes.label}>
        {label}
      </Typography>}
      <Autocomplete
        id={label && (label === 'Your occupation' || label === 'Tell us about your occupation') && 'cus_occupation'} // NOTE - Requested by QA team
        style={{ width: "100%" }}
        disableListWrap
        classes={{
          option: classes.option,
          inputRoot: classes.inputRoot
        }}
        noOptionsText={'This option is not on our list. Please select an alternative which is closest to your occupation or industry.'}
        options={options}
        popupIcon={<KeyboardArrowDown />}
        closeIcon={null}
        PaperComponent={CustomPaper}
        value={field ? field.value : ''}
        onChange={handleChange}
        renderOption={(option, state) => {
          if (state.selected) return (
            <Box display='flex' className={classes.box}>
              <Box flexGrow={1}>{option}</Box>
              <Box className='checkIcon'>
                <CheckIcon color='primary' fontSize='small' />
              </Box>
            </Box>
          )
          else return option;
        }}
        placeholder={'Search'}
        renderInput={params => (
          <TextField
            {...params}
            className={classes.input}
            placeholder='Start typing to search...'
            variant="outlined"
            fullWidth
            error={Boolean(error)}
          />
        )}
      />
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}