import React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';

const CheckboxWrapper = (props) => {
    let { label, color, margin, onChange, checked, form, field, disabled=false } = props

    let formikProps = {};
    if(form) {
        formikProps = {
            field,
            checked: field.value,
            value: field.value ? 'checked' : '',
        }
    }

    return (
        <FormControlLabel margin={margin ? margin : 'normal'}
            control={
                <Checkbox 
                    checked={checked ? true : false} 
                    onChange={onChange}
                    color={color ? color : 'primary'}
                    disabled={disabled}
                    {...formikProps}
                />
            }
            label={<span style={{ fontSize: '1rem', color: '#626680' }}>{label}</span>}
        />
    )
}

export default CheckboxWrapper;