import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    flag: {
        width: '21px',
        height: '15px',
        borderRadius: '3px',
        backgroundColor: '#fff'
    }
}));

const FlagIcon = ({ code, className }) => {
    const classes = useStyles();

    return code ? <img alt={code} src={`${process.env.AZURE_COMMON_STORAGE_ENDPOINT}/country-flags/${code}.svg`} className={className ? `${classes.flag} ${className}`: `${classes.flag}`} /> : null;
}

export default FlagIcon;
