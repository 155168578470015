import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import React, { useEffect, useState } from "react";
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

function PersonCompanyToggleButton(props) {
    const { variant, color, size, onChange, fieldProps, isClient, disabled, flow } = props;
    const router = useRouter();
    const { t } = useTranslation(`${flow}`);
    const PERSON = 'individual';
    const COMPANY = 'business';
    const [accountType, setAccountType] = useState('individual');

    useEffect(() => {
        if (router && router.query && router.query.business) handleAccountType(null, "business")
    }, [router])

    const handleAccountType = (event, newAccountType) => {
        if (newAccountType) {
            setAccountType(newAccountType);
            onChange(newAccountType)
        }
    };

    const useStyles = makeStyles(theme => ({
        selectedButton: {
            borderColor: `${theme.palette.primary.main} !important`,
            borderLeftColor: `${theme.palette.primary.main} !important`,
            backgroundColor: 'white !important',
            color: `${theme.palette.primary.main} !important`,
        },
        buttonGroup: {
            width: '100%',
            border: '0 8px 8px 0'
        },
        button: {
            justifyContent: 'left',
            paddingLeft: theme.spacing(3),
            marginBottom: theme.spacing(1),
            width: '50%',
            height: '40px',
            borderRadius: '0 8px 8px 0',
            '&:first-child': {
                borderRadius: '8px 0 0 8px'
            }
        },
        icon: {
            marginRight: "10px"
        },
        label: {
            fontSize: '12px',
            fontWeight: 400,
            '& svg': {
                width: '18px',
                height: '18px'
            }
        },
    }));
    const classes = useStyles();

    function Icon(props) {
        return (
            props.active ?
                <RadioButtonCheckedIcon className={classes.icon} />
                : <RadioButtonUncheckedIcon className={classes.icon} />
        );
    }

    return (
        <Grid item {...props}
            color={color ? color : 'primary'}
            variant={variant ? variant : 'contained'}
            size={size ? size : 'large'}>
            <ToggleButtonGroup
                value={accountType}
                exclusive
                size='small'
                onChange={handleAccountType}
                aria-label="client type"
                className={classes.buttonGroup}
                {...fieldProps}
            >
                <ToggleButton disabled={disabled} size='small' id={PERSON} name={PERSON} value={PERSON} classes={{ label: classes.label }} className={`${classes.button} ${(accountType == PERSON ? classes.selectedButton : null)}`} aria-label="person">
                    {<Icon active={accountType == PERSON} />}
                    {isClient ? t('person_label') : t('person_label_ben')}
                </ToggleButton>
                <ToggleButton disabled={disabled} size='small' id={COMPANY} name={COMPANY} value={COMPANY} classes={{ label: classes.label }} className={`${classes.button} ${(accountType == COMPANY ? classes.selectedButton : null)}`} aria-label="company">
                    {<Icon active={accountType == COMPANY} />}
                    {isClient ? t('business_label') : t('business_label_ben')}
                </ToggleButton>
            </ToggleButtonGroup>
        </Grid>
    )
}

PersonCompanyToggleButton.defaultProps = {
    flow: 'send-money',
}

export default PersonCompanyToggleButton;
