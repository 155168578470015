import React from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { any, bool, string } from 'prop-types';

const useStyles = makeStyles(() => ({
  circularProgressStyle: {
    display: 'block',
    position: 'absolute',
    marginTop: 0,
    marginBottom: 0,
  },
  button: {
    height: '48px'
  }
}));

const DefaultButton = (props) => {
  const { children, variant, color, size, loading, disabled } = props

  const propsRequired = { ...props }
  delete propsRequired.loading

  const classes = useStyles()
  return (
    <Button
      className={classes.button}
      {...propsRequired}
      color={color}
      disabled={disabled}
      variant={variant}
      size={size}>
      {children}
      {loading && <CircularProgress size={30} className={classes.circularProgressStyle} />}
    </Button>
  )
};

DefaultButton.propTypes = {
  color: string,
  variant: string,
  size: string,
  children: any,
  loading: bool,
  disabled: bool
}

DefaultButton.defaultProps = {
  color: 'primary',
  variant: 'contained',
  size: 'large',
}

export default DefaultButton;