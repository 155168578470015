import { IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import React from 'react';
import StarIcon from '@material-ui/icons/Star';

const useStyles = makeStyles(() => ({
	listItemStyle: {
		width: '100%',
		minHeight: '65px',
		border: '1px solid #CACCD5',
		borderRadius: '5px',
		padding: '20px 30px 20px 30px',
		boxShadow: 'none',
		marginTop: '15px',
		height: '100%',
		'&:hover': {
			border: '1px solid #1160F7',
			'& + div > button > span > svg > circle': {
				fill: '#1160F7'
			}
		},
		'&:active': {
			border: '1px solid #1160F7'
		},
		'& + div > button > span > svg > circle': {
			fill: '#717275'
		}
	},
	listItemInfoStyle: {
		width: '90% !important',
		"@media (max-width: 400px)": {
			display: "flex",
			flexDirection: "column"
		},
		paddingLeft: '10px'
	},
	itemSelected: {
		border: '1px solid #1160F7',
	},
	labelTitle: {
		fontSize: 20,
		fontWeight: 500,
		letterSpacing: '0.7px',
		lineHeight: '25px',
		color: '#37517A',
		"@media (max-width: 959px)": {
			fontSize: 18
		}
	},
	label: {
		fontSize: 16,
		letterSpacing: '0.7px',
		color: '#626680',
		lineHeight: '25px',
		fontWeight: 'normal',
		"@media (max-width: 959px)": {
			fontSize: 14
		}
	},
	recommendedTag: {
		display: 'flex',
		alignItems: 'center',
		position: 'absolute',
		top: '-10px',
		right: '5%',
		background: 'linear-gradient(144.36deg, #268000 8.24%, #4BC54E 72.3%, #7BFF5A 125.1%)',
		fontSize: 14,
		lineHeight: '20px',
		color: '#FFFFFF',
		fontWeight: 500,
		padding: '2px 10px',
		borderRadius: '5px'
	},
	icon: { marginRight: 5, fontSize: 16 }, 
	smallText: {
		display: 'inline',
		fontSize: 14,
		color: '#31517A',
		"@media (max-width: 959px)": {
			fontSize: '12px',
		},
	},
	greenText: { color : '#4BC54E' },
	bold: { fontWeight: 500 },
	mt_20: { marginTop: 20 },
	mb_10: { marginBottom: 10 },
	rightPos: { right: 30 }
}))
  
export function ArrowList(props){
	const classes = useStyles();
	const { options, onChange, isSelected } = props;
	
	return(
		<div>
			<List>
				{options && options.length && options.map((option, index) => 
					<ListItem
						key={index}
						className={classnames(classes.listItemStyle, { [classes.itemSelected]: isSelected === option.key })}
						dense
						button
						onClick={() => onChange(option.key)}
					>
						{option.recommended ? <span className={classes.recommendedTag}><StarIcon className={classes.icon} />  Recommended</span> : null}
						<div className={classes.listItemInfoStyle}>
							{option.title && <ListItemText className={classes.mb_10} primary={<Typography variant='h1' className={classes.labelTitle}>{option.title}</Typography>} />}
							<ListItemText primary={<Typography variant='body1' className={classes.label}>{option.label}</Typography>} />
							{option.sub_title && <ListItemText className={classes.mt_20} primary={
								<>
									<Typography className={classnames(classes.bold, classes.smallText)} variant="h3">{option.sub_title}</Typography>
									{option.sub_label && 
										<Typography className={classnames(classes.smallText, { [classes.greenText]: option.recommended })} >
											{option.sub_label}
										</Typography>
									}
								</>
							} />}
						</div>
						<ListItemSecondaryAction className={classes.rightPos}>
							<IconButton edge="end" aria-label="delete" onClick={() => onChange(option.key)}>
							<svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="https://www.w3.org/2000/svg">
								<circle cx="14.8254" cy="15.5203" r="14.5696" />
								<g clipPath="url(#clip0)">
								<path d="M14.8249 9.69189L13.7653 10.7515L17.7767 14.7629H8.99707V16.2766H17.7767L13.7653 20.288L14.8249 21.3476L20.6527 15.5197L14.8249 9.69189Z" fill="white"/>
								</g>
								<defs>
								<clipPath id="clip0">
								<rect width="11.6557" height="11.6557" fill="white" transform="translate(8.99707 9.69189)"/>
								</clipPath>
								</defs>
							</svg>
							</IconButton>
						</ListItemSecondaryAction>
					</ListItem>
				)}          
			</List>
		</div>
	)
}

export default ArrowList; 
